import React, {useEffect} from "react";
import Navbar from "./components/Navbar";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Jobs from "./pages/Jobs/Jobs";
import Footer from "./components/Footer";
import Investment from "./pages/Investment/Investment";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Container from "react-bootstrap/Container";

function App() {
  const darkMode = true;

  const toggleTheme = () => {
    // setDarkMode(!darkMode);
    document.documentElement.setAttribute('data-bs-theme', darkMode ? 'dark' : 'light');
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  return (
      <Router>
        <Container fluid className={"p-0 main-container"}>
          <Navbar darkMode={darkMode} toggleTheme={toggleTheme}/>
          <Routes>
            <Route path="/" element={<Home darkMode={darkMode}/>}/>
            <Route path="/jobs" element={<Jobs darkMode={darkMode}/>}/>
            <Route path="/about" element={<About darkMode={darkMode}/>}/>
            <Route path="/investment" element={<Investment darkMode={darkMode}/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
          </Routes>
        </Container>
        <Footer/>
      </Router>
  );
}

export default App;
