import React from "react";
import {Col, Container, Row} from "react-bootstrap";

function Investment({darkMode}) {

  return (
      <Container fluid className={`home-section m-0 scrollable-content ${darkMode ? 'bg-black' : 'bg-light'}`} id="home">
          <Container className="home-content">
              <Row className={"home-header-0 pt-5 mt-5 mt-md-2"}>
                  <Col className={"d-flex home-header-text-div"} style={{paddingLeft: "0.5em"}}>
                      <h7>
                          Unichunk is a hedge fund firm that uses machine learning methods to develop algorithms for executing
                          investments.
                      </h7>
                  </Col>
              </Row>
              <Row className={"justify-content-center"}>
                  <Col className="home-header text-start">
                      <p>
                          For any investment-related inquiries, please contact us at <a href="mailto:info@unichunk.com">
                          info@unichunk.com.</a>
                      </p>
                  </Col>
              </Row>
          </Container>
      </Container>
  );
}

export default Investment;
