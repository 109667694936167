import { Container, Row, Col } from "react-bootstrap";
import React from "react";

function Jobs({darkMode}) {

    return (
        <Container fluid className={`home-section m-0 scrollable-content ${darkMode ? 'bg-black' : 'bg-light'}`} id="home">
            <Container className="home-content">
                <Row className={"home-header-0 pt-5 mt-5 mt-md-2"}>
                    <Col className={"d-flex home-header-text-div"} style={{paddingLeft: "0.5em"}}>
                        <h7>
                            Unichunk is a hedge fund firm that uses machine learning methods to develop algorithms for executing
                            investments.
                        </h7>
                    </Col>
                </Row>
                <Row className={"justify-content-md-start"}>
                    <Col className="home-header text-start" md={7}>
                        <p>
                            We are hiring Ph.D. researchers who specialize in statistics, particularly in probability
                            theory and stochastic processes, as well as in computer science with a focus on
                            machine learning. No financial background is required.
                            <br/>
                            Email us at <a href="mailto:info@unichunk.com">info@unichunk.com</a> with a brief
                        explanation in less than two sentences about why
                            you think you're well-suited for algorithmic trading.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Jobs;