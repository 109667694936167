import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoSmall from "../assets/logo-small.svg"


function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer footer-dark-bg p-0">
        <Container fluid className={"text-center bg-black pb-1 footer-quote"}>
            <p className={"m-0"}>
                PAST PERFORMANCE IS NO GUARANTEE OF FUTURE RESULTS.
            </p>
        </Container>
        <Container>
            <Row>
                <Col md="4" className="footer-copyright p-0">
                    <div className={"d-flex align-items-center justify-content-center justify-content-md-start"}>
                        <img src={logoSmall} alt="logo" className={"footer-logo"}/>
                        <h3>Copyright © {year} Unichunk LLC. All rights reserved.</h3>
                    </div>
                </Col>
                <Col md="4" className="footer-copyright">
                <h3>Washington DC, USA.</h3>
                </Col>
                <Col md="4" className="footer-body p-0 align-items-center text-md-end">
                    <h3>Contact Us: info@unichunk.com</h3>
                </Col>
            </Row>
        </Container>
    </Container>
  );
}

export default Footer;