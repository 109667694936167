import React from "react";
import { Container } from "react-bootstrap";

function About({darkMode}) {

  return (
    <Container fluid className={`about-section ${darkMode ? 'bg-black' : 'bg-light'}`}>
      <Container>
      </Container>
    </Container>
  );
}

export default About;
