import React, {useEffect, useRef} from "react";

function Type() {

    const typingSpeed = 100; // Adjust the typing speed in milliseconds

    const outputElement = useRef(null);

    useEffect(() => {
        const sentences = [
            "A unique AI generated chunk in the market.",
            // Add more sentences as needed
        ];

        let sentenceIndex = 0;
        let charIndex = 0;

        function type() {
            if (sentenceIndex < sentences.length) {
                const sentence = sentences[sentenceIndex];
                if (charIndex < sentence.length) {
                    outputElement.current.textContent = sentence.substring(0, charIndex + 1);
                    charIndex++;
                    setTimeout(type, typingSpeed);
                } else {
                    charIndex = 0;
                    sentenceIndex++;
                    setTimeout(type, typingSpeed * 2); // Pause before next sentence
                }
            } else {
                sentenceIndex = 0;
                setTimeout(type, typingSpeed * 2); // Restart the typing animation
            }
        }

        type(); // Start the typing animation
    }, [typingSpeed]);

    return (
        <div className={"Typewriter__wrapper"} ref={outputElement} style={{color: "white"}}/>
    )

}

export default Type;
